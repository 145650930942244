@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');

:root {
    /* dark shades of primary color*/
    --clr-primary-1: hsl(205, 86%, 17%);
    --clr-primary-2: hsl(205, 77%, 27%);
    --clr-primary-3: hsl(205, 72%, 37%);
    --clr-primary-4: hsl(205, 63%, 48%);
    /* primary/main color */
    --clr-primary-5: hsl(205, 78%, 60%);
    /* lighter shades of primary color */
    --clr-primary-6: hsl(205, 89%, 70%);
    --clr-primary-7: hsl(205, 90%, 76%);
    --clr-primary-8: hsl(205, 86%, 81%);
    --clr-primary-9: hsl(205, 90%, 88%);
    --clr-primary-10: hsl(205, 100%, 96%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --clr-red-dark: hsl(360, 67%, 44%);
    --clr-red-light: hsl(360, 71%, 66%);
    --clr-green-dark: hsl(125, 67%, 44%);
    --clr-green-light: hsl(125, 71%, 66%);
    --clr-black: #222;
    --transition: all 0.3s linear;
    --spacing: 0.1rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    --fixed-width: 620px;
  }

body {
    background-color: #36454F;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.containerHome{
    display: flex;
    justify-content: center;
}

.contactSentence{
  color: white;
  font-size: 2rem;
}

.york{
  display: flex;
  justify-content: center;
  transform: scale(0.5, 0.5);
}

.containerProjects{
  display: flex;
  justify-content: center;

}

.educationText{
  display: flex;
  height: 15vh;
  margin-top: -2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: white;
}

.school{
  color: #DC143C;
}

.center{
  margin: auto;
  justify-content: center;
}

 /*For medium screens */
 @media all and (max-width: 800px) {
  .york {
    transform: scale(0.4, 0.4);
  }

  .contactSentence{
    font-size: 1.7rem;
    margin-left: 1rem;
  }

  .containerHomeNameTwo{
    margin-left: 1rem;
    font-size: 0.5rem;
  }
}

/*For small screens */
@media all and (max-width: 600px) {
  .york {
    transform: scale(0.3, 0.3);
  }

  .contactSentence{
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .containerHomeNameTwo{
    margin-left: 1rem;
    font-size: 0.5rem;
  }
}

// .containerProjects:last-of-type{
//   margin-right: 3rem;
// }

// .containerProjects:first-of-type{
//   margin-left: 3rem;
// }


.containerHomeExp{
    width: 80vw;
    display: flex;
    text-align: center;
    justify-content: center;
    max-width: var(--fixed-width);
}

.containerHomeName{
    display: flex;
    justify-content: center;
    text-align: start;
    color: white;
}

.containerHomeNameTwo{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    text-align: start;
    color: white;
}

.containerEdu{
  width: 90vw;
  max-width: var(--fixed-width);
  margin: 5rem auto;
}


.nodeJS{
    color: green;
}

.google{
    color: #2196F3;
}

.cSharp{
    color: orange;
}

.firstSentence{
    font-size: 2rem;
}

.name{
    font-size: 5rem;
    margin-left: 2rem;
}

.underline {
    height: 0.25rem;
    width: 5rem;
    background: var(--clr-primary-5);
    margin-left: auto;
    margin-right: auto;
}

.grid-container {
    margin: auto;
    display: grid;
    grid-template-columns: auto;
    grid-gap: 3px;
    background-color: #36454F;
    padding: 10px;
    justify-content: center;
}

.grid-container > div {
    display: flex;
    background-color: white;
    text-align: center;
    justify-content: center;
    padding: 20px 0;
    font-size: 1.5rem;
  }
  /* CSS For Experience */
  .section {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  
  @media screen and (min-width: 992px) {
    .section {
      width: 95vw;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 0rem;
    font-size: 2rem;
    color: white;
  }

  .titleTwo{
    text-align: center;
    margin-bottom: 0rem;
    font-size: 2rem;
    color: black;
  }

  .underline {
    height: 0.25rem;
    width: 5rem;
    background: var(--clr-primary-5);
    margin-left: auto;
    margin-right: auto;
  }
  .container {
    width: 80vw;
    margin: auto;
    justify-content: center;
    text-align: center;
    max-width: var(--fixed-width);
  }
  .experience {
    background: var(--clr-white);
    padding: 1.5rem 2rem;
    border-radius: var(--radius);
    box-shadow: var(--light-shadow);
    transition: var(--transition);
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 5rem;
  }
  .experience:hover {
    box-shadow: var(--dark-shadow);
  }
  .img-container {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 0%;
    margin: auto;
    margin-bottom: 1rem;
  }
  .person-img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: relative;
  }

  .quote-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    transform: translateY(25%);
    background: var(--clr-primary-5);
    color: var(--clr-white);
  }
  
  .title {
    margin-bottom: 0.25rem;
  }
  .company {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    color: var(--clr-grey-1);
    font-size: 1rem;
    text-decoration: underline;
    font-weight: bold;
  }

  .date{
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    color: var(--clr-grey-1);
    font-size: 1rem;
  }

  .info {
    margin-bottom: 0.75rem;
  }
  .prev-btn,
  .next-btn {
    color: var(--clr-primary-7);
    font-size: 1.25rem;
    background: transparent;
    border-color: transparent;
    margin: 0 0.5rem;
    transition: var(--transition);
    cursor: pointer;
  }
  .prev-btn:hover,
  .next-btn:hover {
    color: var(--clr-primary-5);
  }
  .random-btn {
    margin-top: 0.5rem;
    background: var(--clr-primary-10);
    color: var(--clr-primary-5);
    padding: 0.25rem 0.5rem;
    text-transform: capitalize;
    border-radius: var(--radius);
    transition: var(--transition);
    border-color: transparent;
    cursor: pointer;
  }
  .random-btn:hover {
    background: var(--clr-primary-5);
    color: var(--clr-primary-1);
  }
  
  .icon{
    font-size: 4rem;
    color: red; 
  }

  /* CSS For projects */

  .loading {
    text-align: center;
  }
  .titleProj {
    text-align: center;
    margin-bottom: 4rem;
  }
  .underline {
    width: 6rem;
    height: 0.25rem;
    background: var(--clr-primary-5);
    margin-left: auto;
    margin-right: auto;
  }
  
  .single-project {
    background: var(--clr-white);
    border-radius: var(--radius);
    margin: 2rem 0;
    box-shadow: var(--light-shadow);
    transition: var(--transition);
  }
  .single-project:hover {
    box-shadow: var(--dark-shadow);
  }
  .single-project img {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }
  .project-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem;
    margin-bottom: 1.5rem;
  }
  .project-info h4 {
    margin-bottom: 0;
  }

  .project-desc p {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
  }

  .single-project button {
    background: transparent;
    border-color: transparent;
    text-transform: capitalize;
    color: var(--clr-primary-5);
    font-size: 1rem;
    cursor: pointer;
    padding-left: 0.25rem;
  }
  .project-skills {
    color: var(--clr-primary-5);
    background: var(--clr-primary-10);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
  }

  .containerProj{
    width: 90vw;
    max-width: var(--fixed-width);
    margin: 5rem auto;
  }

  .single-project .link-btn {
    display: block;
    width: 200px;
    margin: 1rem auto 0 auto;
    color: var(--clr-red-dark);
    letter-spacing: var(--spacing);
    background: transparent;
    border: 1px solid var(--clr-red-dark);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
  }

  .button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    margin-bottom: 3rem;
  }

  .button5 {
    background-color: white;
    color: black;
    border: 2px solid #555555;
  }
  
  .button5:hover {
    background-color: #555555;
    color: white;
  }